
import { Typography, Box, Card, CardContent } from '@mui/material';
import React from 'react';

const DownPage = () => {
    return (
        <Box sx={{ my: 2, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-evenly", height: "100%" }} className="bg-transparent">
            <Card sx={{ maxWidth: 900, width: "50%", margin: "40px" }}>
                <CardContent>
                    <Typography variant='h3'>Site Temporarily Down</Typography>
                    <Typography>We apologize for the inconvenience, but the site is currently undergoing maintenance.</Typography>
                    <Typography>If you have any inquiries about DIA, please reach out to mikeguyon98@gmail.com or visit our Github at <a href="https://github.com/mikeguyon98/docker-optimizer-fe">https://github.com/mikeguyon98/docker-optimizer-fe</a></Typography>
                    <Typography>Thank you for your patience.</Typography>
                </CardContent>
            </Card>
        </Box>
    );
}

export default DownPage;
